<template>
  <top-card title="BIO"></top-card>
  <horizontal-img :theImg="imgmeta[0]" v-if='$store.state.width<=1000'></horizontal-img>
  <vertical-img :theImg="imgmeta[0]" v-if='$store.state.width>1000'></vertical-img>
  <play-ground :theImg="imgmeta[1]"></play-ground>
</template>

<script>
import TopCard from '../../../basic/TopCard.vue'
import PlayGround from './PlayGround.vue'
import HorizontalImg from './HorizontalImg.vue';
import VerticalImg from './VerticalImg.vue'
export default {
  components: {
    TopCard,
    HorizontalImg,
    VerticalImg,
    PlayGround
  },
  data () {
    return {
      baseUrl:this.$store.state.baseUrl,
      imgmeta: this.$store.state.bio
    }
  }
}
</script>

<style scoped></style>
