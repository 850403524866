<template>
    <a :href="link.url">
      <img
        :src="link.img"
      >
      </a>
      <a :herf="link.url"> {{link.url}}</a>
      <!-- <br> -->
</template>

<script>
export default {
  props: ["link"],
};
</script>

<style  scoped>
a {
 margin-left: 1vw;
 margin-right: 1vw;
 font-size: calc(10px + max(0.6vw, 0.6vh));
 color: black;
}
img {
  width: calc(12px + max(1vw, 0.6vh));
  height: calc(12px + max(1vw, 0.6vh));
}
</style>