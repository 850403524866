<template>
  <the-navigater
    @set-page="navClick"
    :black="navBlack"
    v-if="!$store.state.showDialog"
  ></the-navigater>

  <the-home v-if="actPage === 'home'"></the-home>
  <Portfolio v-if="actPage === 'portfolio'"></Portfolio>
  <Bio v-if="actPage === 'bio'"></Bio>
  <the-footer></the-footer>
</template>

<script>
import TheFooter from './components/layouts/TheFooter'
import TheHome from './components/layouts/theBody/home/Home'
import TheNavigater from './components/layouts/TheNavigater'
import Portfolio from './components/layouts/theBody/portfolio/Portfolio.vue'
import Bio from './components/layouts/theBody/bio/Bio'
import axios from 'axios'
export default {
  mounted () {
    // const sleep = milliseconds => {
    //   return new Promise(resolve => setTimeout(resolve, milliseconds))
    // }
    // sleep(5000).then(() => {
    //   //do stuff
    // })
    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)
    window.addEventListener('scroll', this.handleScroll)
    
    // const Httpreq = new XMLHttpRequest() // a new request
    // Httpreq.open(
    //   'GET',
    //   'https://raw.githubusercontent.com/tonystevenj/stevenj-studio-photo/master/imgmeta.json',
    //   false
    // )
    // Httpreq.send(null)
    // const imgmeta = JSON.parse(Httpreq.responseText)
    axios
      .get(
        'https://raw.githubusercontent.com/tonystevenj/stevenj-studio-photo/master/imgmeta.json'
      )
      .then(response => {
        const imgmeta = response.data
        this.$store.state.baseUrl = imgmeta['base-url']
        this.$store.state.preview = imgmeta['preview']
        this.$store.state.bio = imgmeta['bio']
        this.$store.state.carousel = imgmeta['carousel']
        this.$store.state.carouselM = imgmeta['carousel-m']
        this.$store.state.portfolio = imgmeta['portfolio']
        if (this.$store.state.portfolio.length % 2 == 1) {
          this.$store.state.portfolio.push({
            name: '',
            url: '',
            date: '',
            location: '',
            description: '',
            class: '',
            labels: [],
            pinned: ''
          })
        }
      })

  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.resizeHandler)
  },
  components: {
    TheHome,
    TheFooter,
    TheNavigater,
    Portfolio,
    Bio
  },
  beforeCreate () {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      //移动端
      // console.log("这是移动端");
      // if(window.location.hostname !="m.tonystevenj.com")
      // window.location.replace("https://m.tonystevenj.com")
    } else {
      //PC端
      // console.log("这是PC端");
      // if(window.location.hostname !="www.tonystevenj.com")
      // window.location.replace('https://www.tonystevenj.com')
    }
  },
  data () {
    return {
      actPage: 'home',
      navBlack: false,
      carouselHeight: 975
    }
  },
  methods: {
    navClick (pageName) {
      this.actPage = pageName
      // console.log("emit works!  " + pageName);
      if (pageName === 'home') this.navBlack = false
      else this.navBlack = true
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    },
    resizeHandler () {
      this.$store.state.width = window.innerWidth
      this.$store.state.height = window.innerHeight
      this.$store.state.longScreen =
        window.innerHeight > window.innerWidth * 1.3 ? true : false
    },
    handleScroll (event) {
      // let tpScrollTop = document.getElementById('testPaper').scrollTop;
      // console.log(tpScrollTop);
      if (document.getElementById('theCarousel'))
        this.carouselHeight = document.getElementById(
          'theCarousel'
        ).offsetHeight
      let Yloc = event.path[1].scrollY
      // console.log(Yloc);
      if (Yloc < this.carouselHeight && this.actPage == 'home') {
        // console.log(Yloc+"哈哈");
        this.navBlack = false
      } else {
        this.navBlack = true
      }

      // Any code to be executed when the window is scrolled
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

* {
  /* font-family: 'Julius Sans One', sans-serif; */
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}
.center {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
@keyframes move-in-right {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
  }
}

@keyframes move-in-left {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
  }
}
</style>
