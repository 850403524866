<template>
  <div>
    <div
      id="carouselExampleControls"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <!-- <img
          src="https://raw.githubusercontent.com/tonystevenj/photo-1920/main/IMG_2992.jpg"
          class="d-block w-100"
          alt="..."
        /> -->
          <img
            :src="imgmeta[0] ? $store.state.baseUrl + imgmeta[0].url : null"
            class="d-block w-100"
            alt="loading img"
          />
        </div>
        <!-- <div class="carousel-item">
        <img
          src="../../../../assets/carousel/3.jpg"
          class="d-block w-100"
          alt="..."
        />
      </div> -->
        <div class="carousel-item">
          <img
            :src="imgmeta[1] ? $store.state.baseUrl + imgmeta[1].url : null"
            class="d-block w-100"
            alt="loading img"
          />
        </div>

        <div class="carousel-item">
          <img
            :src="imgmeta[2] ? $store.state.baseUrl + imgmeta[2].url : null"
            class="d-block w-100"
            alt="loading img"
          />
        </div>
        <!-- <div class="carousel-item">
        <img src="../../../../assets/carousel/5.jpg" class="d-block w-100" alt="..." />
      </div> -->
        <!-- <div class="carousel-item">
        <img src="../../../../assets/carousel/1.jpg" class="d-block w-100" alt="..." />
      </div>
      <div class="carousel-item">
        <img src="../../../../assets/carousel/3.jpg" class="d-block w-100" alt="..." />
      </div>  -->
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <!-- <span
        class="carousel-control-prev-icon"
        aria-hidden="true"
      ></span> -->
        <!-- <span class="sr-only">Previous</span> -->
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <!-- <span
        class="carousel-control-next-icon"
        aria-hidden="true"
      ></span>
      <span class="sr-only">Next</span> -->
      </a>
    </div>
    <div :class="getFlyClass">
      <div :class="getNameSize">Steven J. Wang</div>
      <div :class="center + ' ' + getMottoSize">
        Every moment is worth remembering.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['imgmeta'],
  data () {
    return {}
  },
  computed: {
    getNameSize () {
      return 'name'
    },
    getMottoSize () {
      return 'motto'
    },
    getFlyClass () {
      return this.$store.state.longScreen ? 'fly-m' : 'fly'
    }
  }
}
</script>

<style scoped>
.fly {
  position: absolute;
  z-index: 1000;
  top: 15vw;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  /* display: table; */
  /* display: block; */
}
.fly-m {
  position: absolute;
  z-index: 1000;
  top: 40vw;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  /* display: table; */
  /* display: block; */
}
.name {
  font-size: calc(30px + 2vw);
  animation-name: move-in-right;
  animation-duration: 0.8s;
}
.motto {
  font-family: 'Nothing You Could Do', cursive;
  font-size: calc(20px + 0.5vw);
  animation-name: move-in-left;
  animation-duration: 0.8s;
}
.carousel-control-prev:hover {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  animation: fade-in;
  animation-duration: 0.5s;
}

.carousel-control-prev {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  animation: fade-out;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.carousel-control-next:hover {
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  animation: fade-in;
  animation-duration: 0.5s;
}
.carousel-control-next {
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  animation: fade-out;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
