<template>
  <!-- <div>This is navigater</div> -->
  <nav :class="black ? 'nav-bgc' : ''">
    <div>
      <div>
        <div
          :class="black ? 'm-btn-b m-btn' : 'm-btn-w m-btn'"
          @click="mBtn"
          v-if="$store.state.longScreen"
        ></div>
        <a
          class="logo"
          :class="black ? 'c-black' : 'c-white'"
          href=""
          @click.prevent="setAct('home')"
          >Steven J.</a
        >
        <ul v-if="!$store.state.longScreen" class="long-ul">
          <!-- <li>< a  >{{actPage}}</></li> -->
          <li>
            <a
              href=""
              @click.prevent="setAct('bio')"
              :class="black ? 'c-black navClass' : 'c-white navClass'"
              >BIO</a
            >
          </li>
          <li>
            <a
              href=""
              @click.prevent="setAct('portfolio')"
              :class="black ? 'c-black navClass' : 'c-white navClass'"
              >PORTFOLIO</a
            >
          </li>
          <li>
            <a
              href=""
              @click.prevent="setAct('home')"
              :class="black ? 'c-black navClass' : 'c-white navClass'"
              >HOME</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <transition name="m-nav-fade">
  <div class="m-nav" v-if="showMNav && $store.state.longScreen" @click="mBtn">
    <div class="m-nav-inner" @click.stop="">
      <div class="nav-m-header">
        <a class="logo c-black" href="" @click.prevent="setActM('home')"
          >Steven J.</a
        >
      </div>
      <li class="m-li">
        <a href="" @click.prevent="setActM('home')" :class="navClassM">HOME</a>
      </li>
      <li class="m-li">
        <a href="" @click.prevent="setActM('portfolio')" :class="navClassM"
          >PORTFOLIO</a
        >
      </li>
      <li class="m-li">
        <a href="" @click.prevent="setActM('bio')" :class="navClassM">BIO</a>
      </li>
    </div>
    <div class="dialog-X" @click.stop="">
      <img src="../../assets/close.svg" @click="mBtn" />
    </div>
  </div>
  </transition>
</template>
<script>
export default {
  props: ['black'],
  data () {
    return {
      actPage: 'home',
      showMNav: false
    }
  },
  methods: {
    setAct (page) {
      this.actPage = page
      // console.log(this.actPage);
      this.$emit('set-page', page)
    },
    setActM (page) {
      this.mBtn()
      this.actPage = page
      // console.log(this.actPage);
      this.$emit('set-page', page)
    },
    mBtn () {
      this.showMNav = !this.showMNav
      // console.log(this.showMNav);
      // this.$store.state.showDialog = ! this.$store.state.showDialog
    }

    // bio() {
    //   console.log("啊哈哈");
    // },
  },
  computed: {
    navClassM () {
      return 'c-black m-a'
    }
    // windowWidth(){
    //   return this.$store.state.width
    // },
    // windowHeight(){
    //   return this.$store.state.height
    // },
    // longScreen(){
    //   return this.$store.state.longScreen
    // }
  }
}
</script>

<style scoped>
/* .slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  transform: translateX(-100%); 

}
.slide-fade-leave-to
 {
  opacity: 0.5;
} */

.m-a {
  font-size: calc(18px + 0.3vw);
}
.m-li {
  list-style: none;
  margin-left: 5%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: 60%;
  border-bottom: 5px solid rgba(228, 214, 206, 1);
}

.nav-m-header {
  background-color: rgba(228, 214, 206, 1);
  height: 10vh;
  width: 100%;
  padding-top: 3vh;
}
.dialog-X {
  position: absolute;
  right: 6%;
  top: 2%;
  color: white;
  cursor: pointer;
  width: calc(30px + 0.3vw);
  height: calc(30px + 0.3vw);
}
img {
  vertical-align: middle;
  max-height: 100%;
  width: auto;
  max-width: 100%;
  height: auto;
}
.m-nav-inner {
  background-color: white;

  width: 80%;
  height: 100%;
}
.m-nav {
  background-color: rgba(0, 0, 0, 0.66);
  position: fixed;
  top: 0%;
  z-index: 1002;
  left: 0%;
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(228, 214, 206, 0); */
  /* animation-name: moveInRight; */
  /* animation-duration: 0.6s; */
}

.m-btn {
  float: left;
  height: calc(30px + 0.3vw);
  width: calc(30px + 0.3vw);
  margin-left: 5vw;
}
.m-btn-w {
  background-image: url('../../assets/menu-white.svg');
}
.m-btn-b {
  background-image: url('../../assets/menu.svg');
}
.nav-bgc {
  /* background-color: rgba(255, 255, 255, 0.37); */
  background-color: rgba(228, 214, 206, 0.37);
}
nav {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
}
.c-black {
  color: black;
}
.c-white {
  color: white;
}
.logo {
  /* font-family: 'Nothing You Could Do', cursive; */
  font-family: 'Bungee', cursive;
  float: left;
  padding-left: 5%;
  z-index: 1000;
  font-size: 22px;
  /* font-size:  calc(22px + 0.1vw); */
  /* font-size: 1.5vw; */
  /* color: white; */
}
.long-ul {
  margin-top: 30px;
  margin-right: 10%;
}
nav .long-ul li {
  margin-left: 2rem;
  list-style-type: none;
  float: right;
  font-size: 16px;
  /* font-size: calc(16px + 0.2vw); */
}
a {
  font-family: 'Lato', sans-serif;
  /* color: white; */
}

@keyframes moveInRight{
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.m-nav-fade-enter-active {
  animation: moveInRight .5s;
}
.m-nav-fade-leave-active {
  animation: moveInRight .5s reverse;
}
@keyframes moveInRight{
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

</style>
