<template>
  <div >
    <div class="main-row" v-if="!$store.state.longScreen">
      <base-img side="left" :theImg="imagePair[0]"></base-img>
      <base-img side="right" :theImg="imagePair[1]"></base-img>
    </div>

    <div class="main-row-m" v-if="$store.state.longScreen">
      <base-img side='m' :theImg="imagePair[0]"></base-img>
      <base-img side='m' :theImg="imagePair[1]"></base-img>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import BaseImg from './BaseImg'
export default {
  props: ['imagePair'],
  components: { BaseImg },
  data () {
    return {}
  }
}
</script>

<style scoped>
.main-row {
  /* box-sizing:content-box; */
  /* width: 80%; */
  margin-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  /* overflow: auto; */
  /* display: block; */
  height: 30vw;
}
.main-row-m {
  /* box-sizing:content-box; */
  /* width: 80%; */
  margin-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  /* overflow: auto; */
  /* display: block; */
  /* height: 60vw; */
}
</style>
