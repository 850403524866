<template>
  <top-card title="PORTFOLIO" p="Let your moments shine through."></top-card>
  <div>
    <base-row
      v-for="i in Math.floor(imgmeta.length / 2)"
      :key="i"
      :imagePair="[imgmeta[2 * (i - 1)], imgmeta[2 * (i - 1) + 1]]"
    >
      <!-- {{images.length}} + {{2*(i-1)}} + {{2*(i-1)+1}} -->
    </base-row>
  </div>
</template>

<script>
import BaseRow from '../../../basic/BaseRow.vue'
import TopCard from '../../../basic/TopCard.vue'
export default {
  components: { BaseRow, TopCard },
  data () {
    return {
      imgmeta: this.$store.state.portfolio,
    }
  },

}
</script>

<style scoped></style>
