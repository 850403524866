<template>
  <div v-if="dialogOpen" class="dialog-base" @click="closeDialog">
    <!-- <dialog open> -->
    <div class="dialog-X">
      <img src="../../assets/close.svg" @click="closeDialog" />
    </div>
    <div class="img-holder">
      <span class="helper"></span>
      <img :src="baseUrl+theImg.url" alt="..." @click.stop="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['theImg', 'dialogOpen'],
  data () {
    return {
      baseUrl:this.$store.state.baseUrl
      //   dialogOpen: false
    }
  },
  computed: {
    // metadata(){
    //   return  Object.values(this.theImg).filter(value=> value)
    // }
  },
  methods: {
    closeDialog () {
      this.$emit('close-dialog')
      //   this.dialogOpen = false
      this.$store.state.showDialog = false
      document.documentElement.style.overflow = 'auto'
    }
  }
}
</script>

<style scoped>
.dialog-base {
  margin: 0px;
  display: table-cell;
  position: fixed;
  top: 0vh;
  width: 100vw;
  left: 0px;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;

  z-index: 1001;

  width: 100%;
  height: 100%;
  border: none;
  display: table-cell;
  /* padding: 2% 5% 2% 5%; */
  /* padding-top: 2%; */
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.36);
}
.dialog-X {
  position: absolute;
  right: 2%;
  top: 2%;
  color: white;
  cursor: pointer;
  width: 2%;
}
/* dialog {
  width: 100%;
  height: 100%;
  border: none;
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.36);
} */
.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
/* dialog img {
} */
img {
  vertical-align: middle;
  max-height:100%;
  width: auto;
  max-width: 100%;
  height: auto;
}
.img-holder {
  width: 100vw;
  /* margin-top: 50vh;
  transform: translateY(-50%); */
  height: 100vh;
  padding: 2%;
  /* display: inline; */
  /* background-color: brown; */
}

p {
  height: calc(5px + 1vw);
  font-size: calc(5px + 1vw);
}

.imgmeta {
  vertical-align: middle;
  text-align: left;
  width: 30vw;
  margin-top: 50vh;
  transform: translateY(-50%);
  color: white;
  /* height: 100vh; */
  /* display: inline; */
  float: right;
  /* background-color: blueviolet; */
}
</style>
