<template>
  <div>
      <div class='item'  v-for="id in matrix[0].length" :key="id" :class="matrix[0][id-1]===0? '':'is-on'"></div>
      <div class='item'  v-for="id in matrix[1].length" :key="id" :class="matrix[1][id-1]===0? '':'is-on'"></div>
      <div class='item'  v-for="id in matrix[2].length" :key="id" :class="matrix[2][id-1]===0? '':'is-on'"></div>
      <div class='item'  v-for="id in matrix[3].length" :key="id" :class="matrix[3][id-1]===0? '':'is-on'"></div>
      <div class='item'  v-for="id in matrix[4].length" :key="id" :class="matrix[4][id-1]===0? '':'is-on'"></div>
  </div>
</template>

<script>
export default {
  components: {},
  methods:{
    // itemClick(event,row,c){
    //     console.log(row+" "+c);
    //     // this.matrix[row][c] = (this.matrix[row][c]+1)%2;
    // },
  },
  props:["matrix"],
  data () {
    
    return {
    }
  }
}
</script>

<style scoped>
div{
    width: 100%; 
    height: 100%;
}
.is-on{
    background-color:  rgb(228, 214, 206);
    
}
.item{
    width: 20%;
    height: 20%;
    float: left;
    /* display: inline; */
}


</style>
