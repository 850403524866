<template>
  <div class='portfolio-blank'>
  </div>
  <div class="p-center">

    <div class='p-title p-child'>
      {{title}}
    </div>
    <div>
    </div>
    <div class='slogan p-child'>
      {{p}}
    </div>
  </div>

</template>

<script>
export default {
  props: ["title", "p"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.p-center {
  text-align: center;
  padding-bottom: 5%;
}
.p-child {
  display: inline-block;
}
.portfolio-blank {
  height: max(calc(100px+6vw),10vw,8vh);
}
.slogan {
  /* font-family: "Nothing You Could Do", cursive; */
  font-family: "Montserrat", sans-serif;
  /* font-family: "Lato", sans-serif; */
  font-size:calc(30px + 0.5vw);
    animation-name: move-in-left;
  animation-duration: 0.8s;
}
.p-title {
  /* font-family: 'Julius Sans One', sans-serif; */
  font-family: "Montserrat", sans-serif;
  /* font-family: "Lato", sans-serif; */
  font-size:calc(50px + 0.5vw);
  /* font-size: 55px; */
  /* font-size: 3vw; */
  animation-name: move-in-right;
  animation-duration: 0.8s;
}
</style>
