<template>
<div class='c-main'>


  <div
  class = 'c-item'
    v-for="link in links"
    :key="link.url"
  >
    <img-links :link="link"></img-links>
  </div>
</div>

</template>

<script>
import ImgLinks from "./ImgLinks";
export default {
  props: ["links"],
  components: { ImgLinks },
  data() {
    return {};
  },
};
</script>

<style scoped>
.c-main{
  margin-top: 4vw;
}
.c-item{
  height: 3vw;
  margin-bottom: 10px;
  /* display: inline-block; */
  /* overflow: auto; */
  text-align: left;
}
</style>
