<template>
  <div class='mycontainer'>
    <div class='sign'>Steven J.</div>
    <div class = 'rights'>© 2021 Steven J. Wang &emsp; All Rights Reserved</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.mycontainer {
  /* margin-top: calc(3vw,3vh); */
  background-color: rgb(228, 214, 206);
  height: calc(120px + 8vw);
  width: 100%;
  padding-top: 2%;
  text-align: center;
}
.rights{
  font-size:calc(12px + 0.4vw);
  padding-bottom: 2%;
}
.sign {
  font-family: "Nothing You Could Do", cursive;
  font-size:calc(50px + 1vw);

}


</style>
