<template>
  <div class="main-pinned">
    <base-row
      v-for="i in Math.floor(imgmeta.length / 2)"
      :key="i"
      :imagePair="[imgmeta[2 * (i - 1)], imgmeta[2 * (i - 1) + 1]]"
    >
      <!-- {{images.length}} + {{2*(i-1)}} + {{2*(i-1)+1}} -->
    </base-row>
    <!-- <base-row :imagePair="[images[2],images[3]]"></base-row> -->
  </div>
</template>

<script>
import BaseRow from '../../../basic/BaseRow.vue'
export default {
  components: { BaseRow },
  data () {
    return {
    }
  },
  computed: {
    imgmeta () {
      const res = []
      for (let index in this.$store.state.portfolio) {
        const tem = this.$store.state.portfolio[index]
        if (tem.pinned === 'true') {
          res.push(tem)
        }
      }
      return res
    }
  }
}
</script>

<style scoped>
.main-pinned {
  /* height: 1000px; */
  /* padding-top: 6%; */
  /* background-color: thistle; */
  /* overflow: auto; */
}
</style>
