<template>
  <div class="p-main">
    <div class="p-sub">
      <letter-box :matrix="matrixL"></letter-box>
    </div>
    <div class="p-sub">
      <letter-box :matrix="matrixO"></letter-box>
    </div>
    <div class="p-sub">
      <letter-box :matrix="matrixV"></letter-box>
    </div>
    <div class="p-sub">
      <letter-box-e :theImg="theImg"></letter-box-e>
    </div>
  </div>
</template>

<script>
import LetterBox from '../../../basic/LetterBox.vue'
import LetterBoxE from '../../../basic/LetterBoxE.vue'
export default {
  components: {
    LetterBox,
    LetterBoxE
  },
  props:["theImg"],
  data () {
    return {
      matrixL: [
        [0, 1, 0, 0, 0],
        [0, 1, 0, 0, 0],
        [0, 1, 0, 0, 0],
        [0, 1, 0, 0, 0],
        [0, 1, 1, 1, 1]
      ],
      matrixO: [
        [0, 1, 1, 1, 0],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [0, 1, 1, 1, 0]
      ],
      matrixV: [
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [0, 1, 0, 1, 0],
        [0, 1, 0, 1, 0],
        [0, 0, 1, 0, 0]
      ]
    }
  }
}
</script>

<style scoped>
.p-main {
  /* background-color: yellow; */
  /* height: 2vw; */
  overflow: auto;
  /* padding-left:10% ; */
  /* padding-right:10% ; */

  text-align: center;
}
.p-sub {
  /* background-color: blue; */
  /* height: 80%; */
  margin-left: 2%;
  margin-right: 2%;
  margin-top: calc(20px + 0.9vw);
  margin-bottom: calc(20px + 0.9vw);
  width: max(10vw, 6vh);
  height: max(10vw, 6vh);
  display: inline-block;
}
</style>
