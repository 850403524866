<template>
  <div v-if="dialogOpen" class="dialog-base" @click="closeDialog">
    <!-- <dialog open> -->
    <div class="dialog-X">
      <img src="../../assets/close.svg" @click="closeDialog" />
    </div>
    <div :class="getImgHolder">
      <span v-if="$store.state.width>$store.state.height" class="helper"></span>
      <img :class="$store.state.width>$store.state.height? '':'img-m'" :src="baseUrl+theImg.url" alt="..." @click.stop="" />
    </div>
    <div :class="getImgmetaClass">
      <!-- <span class="helper"></span> -->
      <!-- <p> metadata</p> -->
      <!-- <p v-for="value in metadata" :key="value" >{{ value }} 哈哈哈</p> -->
      <p v-if="theImg.location">
        <img
          style=" margin-right: 2%;"
          src="../../assets/icons8-location-64.png"
          class = 'icons'
        />
        {{ theImg.location }}
      </p>
      <p v-if="theImg.date">
        <img style=" margin-right: 2%;" src="../../assets/icons8-calendar-12-80.png" class = 'icons'/>
        {{ theImg.date }}
      </p>
      <p v-if="theImg.description">{{ theImg.description }}</p>
    </div>
    <!-- <p v-for="jkey in theImg" :key="jkey"   > {{theImg.jkey}} 哈哈哈 </p> -->
    <!-- </dialog> -->
  </div>
</template>

<script>
export default {
  props: ['theImg', 'dialogOpen'],
  data () {
    return {
      baseUrl:this.$store.state.baseUrl
      //   dialogOpen: false
    }
  },
  computed: {
    getImgHolder(){
      if(this.$store.state.width<=this.$store.state.height)
      return 'img-holder-m'
      else
      return  'img-holder'
    },
    getImgmetaClass(){
      if(this.$store.state.width<=this.$store.state.height)
      return 'imgmeta-m'
      else
      return 'imgmeta'
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close-dialog')
      //   this.dialogOpen = false
      this.$store.state.showDialog = false
      document.documentElement.style.overflow = 'auto'
    }
  }
}
</script>

<style scoped>
.icons{
  max-height: calc(20px + 1vw);
  max-width: calc(20px + 1vw);
}
.dialog-base {
  margin: 0px;
  display: table-cell;
  position: fixed;
  top: 0vh;
  width: 100vw;
  left: 0px;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  z-index: 1001;
  border: none;
  display: table-cell;
  /* padding: 2% 5% 2% 5%; */
  /* padding-top: 2%; */
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.66);
}
.dialog-X {
  position: absolute;
  right: 8vw;
  top: 10vh;
  color: white;
  cursor: pointer;
  width: calc(30px + 0.3vw);
  height: calc(30px + 0.3vw);
}
/* dialog {
  width: 100%;
  height: 100%;
  border: none;
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.36);
} */
.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
/* dialog img {
} */

img {
  vertical-align: middle;
  max-height:90vh;
  width: auto;
  max-width: 100%;
  height: auto;
}
.img-m {
  vertical-align: middle;
  max-height:60vh;
  width: auto;
  max-width: 100%;
  height: auto;

}
.img-holder {
  width: 70vw;
  height: 100vh;
  padding: 2%;
  float: left;
}

.img-holder-m {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  margin: 0px 0px;
  margin-top:15vh;
  display: block;
  
  /* float: left; */
}
.imgmeta-m {
  /* vertical-align: middle; */
  text-align: left;
  width: 100%;
  padding-left: 20vw;
  margin-top: 20px;
  color: white;
  /* float: right; */
}

p {
  /* height: calc(20px + 1vw); */
  font-size: calc(12px + 1vw);
  overflow: auto;
}

.imgmeta {
  vertical-align: middle;
  text-align: left;
  width: 20vw;
  margin-top: 50vh;
  transform: translateY(-50%);
  color: white;
  float: left;
}
</style>
