<template>
  <!-- <div v-if="dialogOpen" class="dialog-base" @click="closeDialog">
    <dialog open>
      <div class="dialog-X">
        <img src="../../assets/close.svg" @click="closeDialog" />
      </div>
      <span class="helper"></span>
      <img :src="theImg" alt="..." @click.stop="showDialog" />
    </dialog>
  </div> -->

  <img-dialog
    :theImg="theImg"
    :dialogOpen="dialogOpen"
    @close-dialog="closeDialog"
  ></img-dialog>
  <div class="main-img" :class="getSide">
    <img
      :src="baseUrl + theImg.url"
      alt=""
      @click="showDialog"
      @error="imageLoadError"
    />
  </div>
</template>

<script>
import ImgDialog from './ImgDialog.vue'
export default {
  components: {
    ImgDialog
  },
  props: ['side', 'theImg'],
  data () {
    return {
      baseUrl: this.$store.state.baseUrl,
      dialogOpen: false
    }
  },
  computed: {
    getSide () {
      if (this.side === 'right') return 'f-right'
      else if (this.side === 'left') return 'f-left'
      else return 'm'
    }
  },
  methods: {
    showDialog () {
      this.dialogOpen = true
      this.$store.state.showDialog = true
      //这里不能直接用 $store.state.showDialog来控制 dialog的显示,
      //因为我有20多个img, 有20多个 dialog, 而 store只有一个 boolean, 入如果用它来控制,
      //一下就会显示20多个出来
      //而本地的dialogOpen则只作用于当前的img
      // document.documentElement.style.overflow = 'hidden'
      // console.log('show')
    },
    closeDialog () {
      this.dialogOpen = false
      this.$store.state.showDialog = false
      // document.documentElement.style.overflow = 'auto'
    },
    imageLoadError () {
      // console.log("url invalid");
    }
  }
}
</script>

<style scoped>
.m {
  width: 100%;
  height: 50%;
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.main-img {
  /* display: inline-block; */
  /* float: left; */
  /* background-color: #fff; */
  text-align: center;
}

.f-left {
  width: 49%;
  height: 100%;
  float: left;
}
.f-right {
  width: 49%;
  height: 100%;

  float: right;
}

img {
  cursor: pointer;
  max-height: 100%;
  width: auto;
  max-width: 100%;
  height: auto;
  animation-name: scaleDown;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
img:hover {
  /* transform: scaleX(1.02) scaleY(1.02);
  transition-duration: 0.3s;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.6); */
  animation-name: scaleUp;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes scaleUp {
  0% {
    transform: scaleX(1) scaleY(1);
  }
  100% {
    transform: scaleX(1.02) scaleY(1.02);
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.6);
  }
}

@keyframes scaleDown {
  0% {
    transform: scaleX(1.02) scaleY(1.02);
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.6);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}
</style>
