<template>
  <div>
      <!-- <h1>Details</h1> -->
      <h1>{{title}}</h1>
        <!-- <p>MS in computer science, graduate 2020 from Florida International University, a photographer & programmer. Loving recording every beauty of the world. Loving Miami. Loving my life.</p> -->
        <p> &emsp; {{p1}}</p>
        <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  props:['title','p1'],
  data() {
    return {};
  },
};
</script>

<style scoped>
h1{
    border-bottom:  solid rgb(228, 214, 206);
    font-size:calc(35px + 0.2vw);
}
p{
    font-size:calc(20px + 0.2vw);
}
div{
    margin-top: 4vw;
}
</style>
