<template>
  <!-- <div>here is home</div> -->
  <div>
    <!-- <Carousel :imgmeta="imgmeta" v-if='!$store.state.longScreen'></Carousel> -->
    <!-- <Carousel :imgmeta="imgmetaM" v-if='$store.state.longScreen'></Carousel> -->
    <Carousel ref="theCarousel"  id = "theCarousel"
      :imgmeta="$store.state.longScreen ? $store.state.carouselM : $store.state.carousel"
    ></Carousel>
    <top-card title="PINNED" p="My favorites in 2021"></top-card>
    <pinned></pinned>
  </div>
</template>

<script>
import TopCard from '../../../basic/TopCard.vue'
import Pinned from '../../theBody/home/Pinned.vue'
import Carousel from './Carousel'
export default {
  components: { Carousel, Pinned, TopCard },
  data () {
    return {
    }
  },
  // mounted () {
  //   console.log('哈哈')
  //   console.log(this.$refs)
  //   console.log(this.$refs.theCarousel)
  //   console.log(this.$refs.theCarousel.$el)
  //   console.log(this.$refs.theCarousel.$el.clientHeight)
  // },
}
</script>

<style scoped></style>
