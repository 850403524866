<template>
  <img-center-dialog :theImg="theImg" :dialogOpen="dialogOpen" @close-dialog="closeDialog"></img-center-dialog>
  <div>
      <div class='item'  v-for="id in matrix[0].length" :key="id" :class="matrix[0][id-1]===0? '':'is-on'"></div>
      <div class='item'  v-for="id in matrix[1].length" :key="id" :class="matrix[1][id-1]===0? '':'is-on'"></div>
      <div class='item'  v-for="id in matrix[2].length" :key="id" :class="matrix[2][id-1]===0? '':'is-on'"></div>
      <div class='item'  v-for="id in matrix[3].length" :key="id" :class="matrix[3][id-1]===0? '':'is-on'"></div>
      <div class='item'  :class="matrix[4][0]===0? '':'is-on'"></div>
      <div class='item'  :class="matrix[4][1]===0? '':'is-on'"></div>
      <div class='item'  :class="matrix[4][2]===0? '':'is-on'"></div>
      <div class='item pointer'  :class="matrix[4][3]===0? 'e-not-on':'is-on'" @click="itemClick(4,3)"></div>
      <div class='item'  :class="matrix[4][4]===0? '':'is-on'"></div>
  </div>
</template>

<script>
import ImgCenterDialog from './ImgCenterDialog.vue';
export default {
  components: {ImgCenterDialog},
  methods:{
    itemClick(row,c){
        this.matrix[row][c] = (this.matrix[row][c]+1)%2;
        // console.log(row+" "+c);
        // console.log(this.matrix[row][c]);
        if(this.matrix[row][c]===1)
        this.showDialog();

    },
    showDialog () {
      this.dialogOpen = true
      this.$store.state.showDialog = true
      // document.documentElement.style.overflow = 'hidden'
    },
    closeDialog () {
      this.dialogOpen = false
      this.$store.state.showDialog = false
      // document.documentElement.style.overflow = 'auto'
    }
  },
   props: ['theImg'],
  data () {
    
    return {
      dialogOpen: false,
      matrix: [
        [1, 1, 1, 1, 0],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 1, 0],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 0, 0]
      ]
    }
  }
}
</script>

<style scoped>
div{
    width: 100%; 
    height: 100%;
}
.is-on{
    background-color: rgb(253, 199, 246);
}
.item{
    width: 20%;
    height: 20%;
    float: left;
}
.pointer {
  cursor: pointer;
}
.e-not-on{
  border: 1px solid rgba(253, 199, 246);

}
</style>
