import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import './registerServiceWorker'

const store = createStore({
    state(){
        return {
            showDialog:false,
            baseUrl:"",
            preview:"",
            bio:[],
            carousel:[],
            carouselM:[],
            portfolio:[],
            width:0,
            height:0,
            longScreen:false
        }
    }
})
const app = createApp(App)
app.use(store)
app.mount('#app')
