<template>
  <div class="v-me">
    <div class="img">
      <img :src="baseUrl + theImg.url" />
    </div>
    <div class="info">
      <bio-card
        title="Details"
        p1="MS in computer science, graduate 2020 from Florida International University, a photographer & programmer. Love recording every beauty of the world. Love Miami. And Love my life."
      ></bio-card>
      <!-- <div class="info-blank"></div> -->
      <!-- <div class="info-blank"></div> -->
      <!-- <div class="info-blank"></div> -->
      <!-- <div class="info-blank"></div> -->
      <bio-card
        title="Contacts"
        p1="If you fall in love with any one of my photos and want the original size, feel free to email me. I'm happy to share!"
      >
        <Contacts :links="links"></Contacts>
      </bio-card>
    </div>
  </div>
</template>

<script>
import BioCard from '../../../basic/BioCard.vue'
import Contacts from '../../../basic/Contacts.vue'
export default {
  components: { BioCard, Contacts },
  props: ['theImg'],
  data () {
    return {
      baseUrl:this.$store.state.baseUrl,
      links: [
        {
          img: require('../../../../assets/icons8-gmail.svg'),
          url: 'tonystevenj@gmail.com'
        },
        {
          img: require('../../../../assets/icons8-facebook.svg'),
          url: 'https://www.facebook.com/tonystevenj'
        },
        {
          img: require('../../../../assets/icons8-instagram.svg'),
          url: 'https://www.instagram.com/tonystevenj'
        },
        {
          img: require('../../../../assets/icons8-github.svg'),
          url: 'https://github.com/tonystevenj'
        },
        {
          img: require('../../../../assets/icons8-linkedin-circled.svg'),
          url: 'https://www.linkedin.com/in/tonystevenj'
        }
      ]
    }
  }
}
</script>

<style scoped>
.v-me {
  /* text-align: center; */
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5vh;
  margin-bottom: 5%;
  /* height: 80vh; */
  overflow: auto;
}
img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}
.img {
  width: 40%;
  float: left;
}

.info {
  float: left;
  margin-left: 10%;
  width: 50%;
  /* position: relative; */
  /* height: 50vw; */
  /* overflow: auto; */
  /* height: 60vw; */
}
.info-blank {
  height: 12vw;
}
/* .f-bottom{
    position: absolute;
    bottom: 0px;
} */
</style>
